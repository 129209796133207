import React from "react";
import Layout from "../../components/layout";

const Loc = () => {
    return (
        <Layout>
            <h1>Localization Team</h1>
        </Layout>
    )
};

export default Loc;
